import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const CreateGroup = () => {
  const { user } = useAuth();
  const [groupName, setGroupName] = useState("");
  const navigate = useNavigate();
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const response = await axios.get(`/api/groups?createdBy=${user.id}`);
        setUserGroups(response.data);
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    };

    fetchUserGroups();
  }, [user.id]);

  const handleCreateGroup = async () => {
    try {
      const newGroup = {
        name: groupName,
        createdBy: user.id,
        members: [user.id], // Add the creator as a member
      };
      await axios.post("/api/groups", newGroup);
      setUserGroups((prevGroups) => [...prevGroups, newGroup]);

      // Optionally, you can redirect the user to another page after group creation
      // Replace '/my-account' with the desired route
      navigate("/contracts");
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-gray-800 p-6 rounded-lg shadow-lg text-white mb-6">
      <h2 className="text-2xl font-bold mb-4">Create Group</h2>
      <label className="block mb-4">
        <span className="text-gray-300">Group Name:</span>
        <input
          type="text"
          className="w-full bg-gray-700 text-white py-2 px-4 mt-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          placeholder="Group Name"
        />
      </label>
      <button
        className="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
        onClick={handleCreateGroup}
      >
        Create Group
      </button>
      <h3 className="text-xl font-bold mt-6">Your Groups</h3>
      <ul className="list-disc list-inside mt-4">
        {userGroups.map((group) => (
          <li key={group.id} className="text-gray-300">
            {group.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CreateGroup;
