import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const OrderPage = () => {
  const { contractId } = useParams();
  const { user } = useAuth();

  const [orderType, setOrderType] = useState("bid");

  const [orderDetails, setOrderDetails] = useState({
    price: "",
    quantity: "",
    askPrice: "",
    askSize: "",
    bidPrice: "",
    bidSize: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (orderType === "two-sided") {
      submitTwoSidedOrders();
    } else {
      submitOneSidedOrder();
    }
  };

  const submitOneSidedOrder = () => {
    const newOrder = {
      type: orderType,
      price: orderDetails.price,
      size: orderDetails.quantity,
      userId: user.id,
      contractId: contractId,
      executionTime: new Date().toISOString(),
    };

    axios
      .post("/api/orders", newOrder)
      .then((response) => {
        console.log("Order placed successfully:", response.data);
        setOrderDetails({ price: "", quantity: "" });
      })
      .catch((error) => {
        console.error("Error placing order:", error);
        // Handle error
      });
  };

  const submitTwoSidedOrders = () => {
    const bidOrder = {
      type: "bid",
      price: orderDetails.bidPrice,
      size: orderDetails.bidSize,
      userId: user.id,
      contractId: contractId,
      executionTime: new Date().toISOString(),
    };

    const askOrder = {
      type: "ask",
      price: orderDetails.askPrice,
      size: orderDetails.askSize,
      userId: user.id,
      contractId: contractId,
      executionTime: new Date().toISOString(),
    };

    axios
      .post("/api/orders", bidOrder)
      .then((response) => {
        console.log("Bid order placed successfully:", response.data);
        setOrderDetails({
          bidPrice: "",
          bidSize: "",
          askPrice: "",
          askSize: "",
        });
      })
      .catch((error) => {
        console.error("Error placing bid order:", error);
        // Handle error
      });

    axios
      .post("/api/orders", askOrder)
      .then((response) => {
        console.log("Ask order placed successfully:", response.data);
        setOrderDetails({
          bidPrice: "",
          bidSize: "",
          askPrice: "",
          askSize: "",
        });
      })
      .catch((error) => {
        console.error("Error placing ask order:", error);
        // Handle error
      });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-10 px-6 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-3xl font-bold mb-6">Place Order</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Order Type:
              <select
                className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)}
              >
                <option value="bid">Bid</option>
                <option value="ask">Ask</option>
                <option value="two-sided">Two-Sided</option>
              </select>
            </label>
          </div>
          {orderType !== "two-sided" && (
            <>
              <div>
                <label>
                  Price:
                  <input
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    type="number"
                    value={orderDetails.price}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        price: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div>
                <label>
                  Quantity:
                  <input
                    type="number"
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={orderDetails.quantity}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        quantity: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </>
          )}
          {orderType === "two-sided" && (
            <>
              <div>
                <label>
                  Ask Price:
                  <input
                    type="number"
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={orderDetails.askPrice}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        askPrice: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div>
                <label>
                  Ask Size:
                  <input
                    type="number"
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={orderDetails.askSize}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        askSize: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div>
                <label>
                  Bid Price:
                  <input
                    type="number"
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={orderDetails.bidPrice}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        bidPrice: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div>
                <label>
                  Bid Size:
                  <input
                    type="number"
                    className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={orderDetails.bidSize}
                    onChange={(e) =>
                      setOrderDetails({
                        ...orderDetails,
                        bidSize: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </>
          )}
          <button
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            type="submit"
          >
            Place Order
          </button>
        </form>
      </div>
    </div>
  );
};

export default OrderPage;
