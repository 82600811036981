import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Navbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleClickOutside = (event) => {
    if (showDropdown && !event.target.closest('.dropdown-menu')) {
      setShowDropdown(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="bg-gray-800 py-4 sticky top-0">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="w-full flex justify-between">
            <Link to="/" className="text-white font-semibold text-xl">TT</Link>
            {user ? (
              <div className="flex space-x-11">
                <Link to="/trading-center" className="text-white hover:text-gray-300">Trading Center</Link>
                <Link to="/positions" className="text-white hover:text-gray-300">Positions</Link>
                <Link to="/user-statistics" className="text-white hover:text-gray-300">User Statistics</Link>
                <Link to="/personal-account" className="text-white hover:text-gray-300">Personal Account</Link>
                <div className="relative inline-block text-left">
                  <button onClick={toggleDropdown} className="text-white hover:text-gray-300">Services</button>
                  {showDropdown && (
                    <div className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-gray-900 ring-1 ring-white ring-opacity-10">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <Link to="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700" role="menuitem">Help</Link>
                        <Link to="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700" role="menuitem">Impressum</Link>
                        <Link to="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700" role="menuitem">Data Privacy</Link>
                        <Link to="#" className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700" role="menuitem">About us</Link>
                      </div>
                    </div>
                  )}
                </div>
                <Link to="/game-master" className="text-white hover:text-gray-300">Game Master</Link>
                <Link to="/admin" className="text-white hover:text-gray-300">Admin</Link>
                <button onClick={handleLogout} className="text-white hover:text-gray-300">Logout</button>
              </div>
            ) : (
              <div className="flex space-x-11">
                <Link to="/positions" className="text-white hover:text-gray-300">Positions</Link>
                <Link to="/user-statistics" className="text-white hover:text-gray-300">User Statistics</Link>
                <Link to="/login" className="text-white hover:text-gray-300">Login</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
