import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Groups = () => {
  const { user } = useAuth();
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");

  useEffect(() => {
    axios
      .get("/api/groups")
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
      });
  }, []);

  const createGroup = () => {
    if (newGroupName.trim() !== "") {
      const newGroup = {
        id: Math.random().toString(36).substr(2, 9),
        name: newGroupName,
        createdBy: user.id,
        members: [user.id],
      };
      axios
        .post("/api/groups", newGroup)
        .then((response) => {
          setGroups([...groups, response.data]);
          setNewGroupName("");
        })
        .catch((error) => {
          console.error("Error creating group:", error);
        });
    }
  };

  const joinGroup = (groupId) => {
    const updatedGroups = groups.map((group) => {
      if (group.id === groupId && !group.members.includes(user.id)) {
        group.members.push(user.id);
        axios
          .put(`/api/groups/${groupId}`, group)
          .then((response) => {
            setGroups(
              groups.map((g) => (g.id === groupId ? response.data : g))
            );
          })
          .catch((error) => {
            console.error("Error joining group:", error);
          });
      }
      return group;
    });
    setGroups(updatedGroups);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white py-10 px-6">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-xl">
        <h1 className="text-3xl font-bold mb-6">Groups</h1>
        <div className="my-6">
          <input
            className="bg-gray-900 text-white border border-gray-700 rounded px-5 py-2 mr-2 focus:outline-none focus:border-indigo-500"
            type="text"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            placeholder="New group name"
          />
          <button
            className="bg-indigo-500 hover:bg-indigo-600 transition-colors text-white font-bold py-2 px-4 rounded"
            onClick={createGroup}
          >
            Create Group
          </button>
        </div>

        <div className="bg-gray-700 rounded-lg p-4 mb-8">
          <h2 className="text-2xl font-bold mb-6">All Groups</h2>
          <ul>
            {groups.map((group) => (
              <li
                key={group.id}
                className="flex justify-between items-center mb-2"
              >
                <span>{group.name}</span>
                {group.members.includes(user.id) ? (
                  <span className="text-gray-400  italic">Joined</span>
                ) : (
                  <button
                    className="bg-indigo-500 hover:bg-indigo-600 transition-colors text-white font-bold py-2 px-4 rounded"
                    onClick={() => joinGroup(group.id)}
                  >
                    Join
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-gray-700 rounded-lg p-4 mb-8">
          <h2 className="text-2xl font-bold mb-6">My Groups</h2>
          <ul>
            {groups
              .filter((group) => group.members.includes(user.id))
              .map((group) => (
                <li key={group.id} className="mb-2">
                  <Link
                    to={`/trade-center/${group.id}`}
                    className="text-blue-400 hover:text-blue-200"
                  >
                    {group.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Groups;
