import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ContractDetails from "./components/ContractDetails";
import Contracts from "./components/Contracts";
import CreateGroup from "./components/CreateGroup";
import GlobalOrderBook from "./components/GlobalOrderBook";
import Groups from "./components/Groups";
import Login from "./components/Login";
import MyAccount from "./components/MyAccount";
import Navbar from "./components/Navbar";
import Order from "./components/Order";
import Positions from "./components/Positions";
import Register from "./components/Register";
import TradeCenter from "./components/TradeCenter";
import { AuthProvider, useAuth } from "./context/AuthContext";

const App = () => {
  const PrivateRoute = ({ element }) => {
    const { user } = useAuth();
    return user ? element : <Navigate to="/login" />;
  };

  return (
    <AuthProvider>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/order/:contractId" element={<Order />} />
          <Route path="/login" element={<Login />} />
          <Route path="/positions/:groupId" element={<Positions />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/contracts"
            element={<PrivateRoute element={<Contracts />} />}
          />
          <Route
            path="/contract/:id"
            element={<PrivateRoute element={<ContractDetails />} />}
          />
          <Route
            path="/contract/:id"
            element={<PrivateRoute element={<ContractDetails />} />}
          />
          <Route
            path="/orderbook"
            element={<PrivateRoute element={<GlobalOrderBook />} />}
          />
          <Route
            path="/trade-center/:groupId"
            element={<PrivateRoute element={<TradeCenter />} />}
          />
          <Route
            path="/my-account"
            element={<PrivateRoute element={<MyAccount />} />}
          />
          <Route
            path="/create-group"
            element={<PrivateRoute element={<CreateGroup />} />}
          />
          <Route
            path="/groups"
            element={<PrivateRoute element={<Groups />} />}
          />
          {/* <Route path="/positions" element={<PrivateRoute element={<Positions />} />} /> */}

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
