import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";

const MyAccount = () => {
  const { user, logout } = useAuth();
  const [invites, setInvites] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch user's invites
    const fetchInvites = async () => {
      try {
        const response = await axios.get(`/api/invites?userId=${user.id}`);
        setInvites(response.data);
      } catch (error) {
        console.error("Error fetching invites:", error);
      }
    };

    // Fetch user's groups
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`/api/groups?createdBy=${user.id}`);
        setGroups(response.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchInvites();
    fetchGroups();
  }, [user.id]);

  const handleSendInvite = async () => {
    try {
      // Check if all required fields are filled
      if (!selectedGroupId || !selectedGroupName || !inviteEmail) {
        setError("Please fill in all required fields.");
        return;
      }

      // Fetch the receiver's ID based on the email
      const receiver = await axios.get(`/api/users?email=${inviteEmail}`);
      if (!receiver || !receiver.data || receiver.data.length === 0) {
        setError("Receiver not found with the provided email.");
        return;
      }

      // Create the invite object with all necessary values
      const newInvite = {
        id: Date.now().toString(), // Assuming you need an ID
        groupId: selectedGroupId,
        groupName: selectedGroupName,
        invitedBy: user.id,
        userId: receiver.data[0].id, // Set the receiver's ID
        email: inviteEmail,
        accepted: false, // By default, the invite is not accepted
      };

      // Send the invite to the backend for storage
      const response = await axios.post("/api/invites", newInvite);
      console.log("Invite sent:", response.data);
    } catch (error) {
      console.error("Error sending invite:", error);
    }
  };

  const handleAcceptInvite = async (inviteId) => {
    try {
      // Accept the invite
      const response = await axios.put(`/api/invites/${inviteId}`, {
        accepted: true,
      });
      setInvites(invites.filter((invite) => invite.id !== inviteId));
      console.log("Invite accepted:", response.data);

      // Join the group
      const invite = invites.find((invite) => invite.id === inviteId);
      const group = await axios.get(`/api/groups/${invite.groupId}`);
      if (group.data && !group.data.members.includes(user.id)) {
        group.data.members.push(user.id);
        // Update the group
        await axios.put(`/api/groups/${invite.groupId}`, group.data);
        console.log("Joined the group:", group.data.name);
      }
    } catch (error) {
      console.error("Error accepting invite:", error);
    }
  };

  const handleRejectInvite = async (inviteId) => {
    try {
      const response = await axios.delete(`/api/invites/${inviteId}`);
      console.log("Invite rejected:", response.data);
      // Remove invite from state
      setInvites(invites.filter((invite) => invite.id !== inviteId));
    } catch (error) {
      console.error("Error rejecting invite:", error);
    }
  };

  const handleGroupChange = (e) => {
    const selectedGroup = groups.find((group) => group.id === e.target.value);
    setSelectedGroupId(selectedGroup.id);
    setSelectedGroupName(selectedGroup.name);
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center p-4">
      <div className="bg-gray-800 rounded-lg p-8 mb-8 w-full max-w-2xl">
        <h2 className="text-3xl font-bold mb-6 text-center">My Account</h2>
        <div className="mb-6">
          <h3 className="text-2xl font-semibold mb-4">Send Invite</h3>
          <div className="mb-4">
            <select
              value={selectedGroupId}
              onChange={handleGroupChange}
              className="bg-gray-700 text-white border border-gray-600 rounded px-3 py-2 w-full mb-4"
            >
              <option value="">Select a group</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
            <input
              type="email"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              placeholder="Enter user's email"
              className="bg-gray-700 text-white border border-gray-600 rounded px-3 py-2 w-full mb-4"
            />
            {error && <p className=" mb-2 text-red-500">{error}</p>}

            <button
              onClick={handleSendInvite}
              className="bg-indigo-500 hover:bg-indigo-600 transition-colors text-white font-bold py-2 px-4 rounded w-full"
            >
              Send Invite
            </button>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-2xl font-semibold mb-4">Invites</h3>
          <ul className="space-y-4">
            {invites.map((invite) => (
              <li
                key={invite.id}
                className="bg-gray-700 rounded p-4 flex justify-between items-center"
              >
                <span>
                  {invite.groupName} - {invite.email}
                </span>
                <div className="space-x-2">
                  <button
                    onClick={() => handleAcceptInvite(invite.id)}
                    className="bg-blue-500 hover:bg-blue-600 transition-colors text-white font-bold py-1 px-2 rounded"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => handleRejectInvite(invite.id)}
                    className="bg-red-500 hover:bg-red-600 transition-colors text-white font-bold py-1 px-2 rounded"
                  >
                    Reject
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
