import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ContractDetails = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [contract, setContract] = useState({});
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState({
    ask: { type: "ask", price: "", size: "" },
    bid: { type: "bid", price: "", size: "" },
  });
  const [twoSided, setTwoSided] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchContract = async () => {
      try {
        const contractResponse = await axios.get(`/api/contracts/${id}`);
        setContract(contractResponse.data);
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    };

    const fetchOrders = async () => {
      try {
        const response = await axios.get(`/api/orders?contractId=${id}`);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchContract();
    fetchOrders();
  }, [id, user]);

  const handleCreateOrder = async () => {
    if (twoSided) {
      // Validate bid and ask orders
      const askPrice = parseFloat(newOrder.ask.price);
      const bidPrice = parseFloat(newOrder.bid.price);
      if (
        isNaN(askPrice) ||
        isNaN(bidPrice) ||
        askPrice <= 0 ||
        bidPrice <= 0 ||
        isNaN(parseFloat(newOrder.ask.size)) ||
        isNaN(parseFloat(newOrder.bid.size)) ||
        parseFloat(newOrder.ask.size) <= 0 ||
        parseFloat(newOrder.bid.size) <= 0
      ) {
        setError("Please enter valid prices and sizes for both ask and bid.");
        return;
      }

      // Check price range
      if (
        askPrice < 0.01 ||
        askPrice > 9.99 ||
        bidPrice < 0.01 ||
        bidPrice > 9.99
      ) {
        setError("Prices must be between 0.01 and 9.99.");
        return;
      }

      const askOrder = {
        ...newOrder.ask,
        userId: user.id,
        contractId: id,
        executionTime: new Date().toISOString(),
      };
      const bidOrder = {
        ...newOrder.bid,
        userId: user.id,
        contractId: id,
        executionTime: new Date().toISOString(),
      };

      await axios.post("/api/orders", askOrder);
      await axios.post("/api/orders", bidOrder);

      setNewOrder({
        ask: { type: "ask", price: "", size: "" },
        bid: { type: "bid", price: "", size: "" },
      });
      setError("");
    } else {
      // Validate single order
      if (
        !newOrder.ask.price ||
        !newOrder.bid.price ||
        isNaN(parseFloat(newOrder.ask.price)) ||
        isNaN(parseFloat(newOrder.bid.price)) ||
        parseFloat(newOrder.ask.price) <= 0 ||
        parseFloat(newOrder.bid.price) <= 0 ||
        isNaN(parseFloat(newOrder.ask.size)) ||
        isNaN(parseFloat(newOrder.bid.size)) ||
        parseFloat(newOrder.ask.size) <= 0 ||
        parseFloat(newOrder.bid.size) <= 0
      ) {
        setError("Please enter valid prices and sizes for both ask and bid.");
        return;
      }

      // Check price range
      const askPrice = parseFloat(newOrder.ask.price);
      const bidPrice = parseFloat(newOrder.bid.price);
      if (
        askPrice < 0.01 ||
        askPrice > 9.99 ||
        bidPrice < 0.01 ||
        bidPrice > 9.99
      ) {
        setError("Prices must be between 0.01 and 9.99.");
        return;
      }

      const askOrder = {
        ...newOrder.ask,
        userId: user.id,
        contractId: id,
        executionTime: new Date().toISOString(),
      };
      const bidOrder = {
        ...newOrder.bid,
        userId: user.id,
        contractId: id,
        executionTime: new Date().toISOString(),
      };

      await axios.post("/api/orders", askOrder);
      await axios.post("/api/orders", bidOrder);

      setNewOrder({
        ask: { type: "ask", price: "", size: "" },
        bid: { type: "bid", price: "", size: "" },
      });
      setError("");
    }

    const response = await axios.get(`/api/orders?contractId=${id}`);
    setOrders(response.data);
  };

  return user ? (
    <div className="min-h-screen bg-gray-900 text-white py-10 px-6 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-3xl font-bold mb-6">Place Order</h1>
        <h2 className="text-2xl font-bold mb-6">{contract.name}</h2>
        <div className="mb-6">
          <input
            type="number"
            step="0.01"
            className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Price"
            value={newOrder.price}
            min="0.01"
            max="9.99"
            onChange={(e) =>
              setNewOrder({ ...newOrder, price: e.target.value })
            }
          />
          <input
            type="text"
            className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Size"
            value={newOrder.size}
            onChange={(e) => setNewOrder({ ...newOrder, size: e.target.value })}
          />
          <select
            className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={newOrder.type}
            onChange={(e) => setNewOrder({ ...newOrder, type: e.target.value })}
          >
            <option value="ask">Ask</option>
            <option value="bid">Bid</option>
          </select>

          <div className="mb-6">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={twoSided}
                onChange={(e) => setTwoSided(e.target.checked)}
              />
              Two-Sided Order
            </label>
          </div>

          {twoSided && (
            <>
              <input
                type="number"
                step="0.01"
                className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Ask Price"
                value={newOrder.ask.price}
                min="0.01"
                max="9.99"
                onChange={(e) =>
                  setNewOrder({
                    ...newOrder,
                    ask: { ...newOrder.ask, price: e.target.value },
                  })
                }
              />
              <input
                type="text"
                className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Ask Size"
                value={newOrder.ask.size}
                onChange={(e) =>
                  setNewOrder({
                    ...newOrder,
                    ask: { ...newOrder.ask, size: e.target.value },
                  })
                }
              />
              <input
                type="number"
                step="0.01"
                className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Bid Price"
                value={newOrder.bid.price}
                min="0.01"
                max="9.99"
                onChange={(e) =>
                  setNewOrder({
                    ...newOrder,
                    bid: { ...newOrder.bid, price: e.target.value },
                  })
                }
              />
              <input
                type="text"
                className="w-full bg-gray-700 text-white py-2 px-4 mb-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Bid Size"
                value={newOrder.bid.size}
                onChange={(e) =>
                  setNewOrder({
                    ...newOrder,
                    bid: { ...newOrder.bid, size: e.target.value },
                  })
                }
              />
            </>
          )}

          <button
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={handleCreateOrder}
          >
            Place Order
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
        <h3 className="text-2xl font-bold mb-4">Orders</h3>
        <ul className="mb-6">
          {orders.map((order) => (
            <li key={order.id} className="bg-gray-700 py-2 px-4 mb-2 rounded">
              {order.type}: {order.price} - {order.size} (Execution Time:{" "}
              {order.executionTime})
            </li>
          ))}
        </ul>
        <Link
          className="text-indigo-500 hover:text-indigo-400"
          to="/trade-center"
        >
          Trade Center
        </Link>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default ContractDetails;
