import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useAuth } from "../context/AuthContext";

const Positions = () => {
  const { user } = useAuth();
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState([]);
  const [liveContracts, setLiveContracts] = useState([]);
  const [settledContracts, setSettledContracts] = useState([]);
  const [openOrdersCount, setOpenOrdersCount] = useState(0);
  const [settledPoints, setSettledPoints] = useState(0);
  const [livePoints, setLivePoints] = useState(0);
  const [userData, setUserData] = useState({});
  const [expandedContract, setExpandedContract] = useState(null);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data from localStorage
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
          console.error("No user found in localStorage");
          navigate("/login");
          return;
        }

        // Fetch all groups where the user is a member
        const groupsResponse = await axios.get("/api/groups");
        const userGroups = groupsResponse.data.filter((group) =>
          group.members.includes(user.id)
        );
        setGroups(userGroups);

        // Set the default selected group
        const defaultGroupId = groupId || userGroups[0]?.id; // Use URL param or first group as default
        setSelectedGroup(defaultGroupId);

        // Fetch live contracts for the selected group
        const liveContractsResponse = await axios.get("/api/contracts", {
          params: {
            groupId: defaultGroupId,
            status: "live", // Assuming there's a status field to differentiate live/settled contracts
          },
        });
        setLiveContracts(liveContractsResponse.data);

        // Fetch settled contracts for the selected group
        const settledContractsResponse = await axios.get("/api/contracts", {
          params: {
            groupId: defaultGroupId,
            status: "settled",
          },
        });
        setSettledContracts(settledContractsResponse.data);

        // Fetch other user-specific data (open orders count, points, etc.)
        // For simplicity, placeholder values are used here.
        const ordersResponse = await axios.get("/api/orders", {
          params: {
            userId: user.id,
          },
        });
        const now = new Date().toISOString();
        const openOrders = ordersResponse.data.filter(
          (order) => order.executionTime > now
        ).length;
        setOpenOrdersCount(openOrders); // Set open orders count

        setSettledPoints(200); // Example: Replace with actual settled points
        setLivePoints(100); // Example: Replace with actual live points
        setUserData({ username: user.username }); // Example: Set user data
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
      }
    };

    fetchData();
  }, [groupId]); // Fetch data whenever groupId changes

  const handleGroupChange = async (groupId) => {
    setSelectedGroup(groupId); // Update selected group

    try {
      // Fetch live contracts for the selected group
      const liveContractsResponse = await axios.get("/api/contracts", {
        params: {
          groupId,
          status: "live",
        },
      });
      setLiveContracts(liveContractsResponse.data);

      // Fetch settled contracts for the selected group
      const settledContractsResponse = await axios.get("/api/contracts", {
        params: {
          groupId,
          status: "settled",
        },
      });
      setSettledContracts(settledContractsResponse.data);

      // Fetch open orders count for the selected group
      const ordersResponse = await axios.get("/api/orders", {
        params: {
          userId: user.id,
        },
      });
      const now = new Date().toISOString();
      const openOrders = ordersResponse.data.filter(
        (order) => order.executionTime > now
      ).length;
      setOpenOrdersCount(openOrders); // Set open orders count
    } catch (error) {
      console.error("Error fetching contracts:", error);
      // Handle error
    }
  };

  const fetchOrders = async (contractId) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const ordersResponse = await axios.get("/api/orders", {
        params: {
          userId: user.id,
          contractId: contractId, // Add contractId to params
        },
      });
      setOrders(ordersResponse.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const toggleContractExpansion = async (contractId) => {
    if (expandedContract === contractId) {
      setExpandedContract(null);
      setOrders([]); // Clear orders when collapsing
    } else {
      setExpandedContract(contractId);
      await fetchOrders(contractId); // Fetch orders for the selected contract
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-10 px-6">
      <div className="container mx-auto">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-4xl mx-auto flex justify-between items-center mb-6">
          <div>
            {/* Dropdown for selecting group */}
            <select
              value={selectedGroup || groupId}
              className="bg-gray-800 text-4xl font-bold mb-2 border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:focus:border-blue-500"
              onChange={(e) => handleGroupChange(e.target.value)}
            >
              {groups.map((group) => (
                <option className="text-xl" key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
            <p className="text-xl">{userData.username}</p>
          </div>
          <div className="text-right">
            {/* Display stats */}
            <p>
              Settled points: <span className="font-bold">{settledPoints}</span>
            </p>
            <p>
              Live Points: <span className="font-bold">{livePoints}</span>
            </p>
            <p>
              Open orders: <span className="font-bold">{openOrdersCount}</span>
            </p>
          </div>
        </div>

        {/* Tabs for Live Contracts and Settled Contracts */}
        <Tabs>
          <TabList className={"flex justify-around"}>
            <Tab
              className={
                "p-5 text-center text-3xl font-bold w-1/2 my-5 bg-red-500 hover:bg-red-600 cursor-pointer focus:bg-red-600  "
              }
            >
              Live Contracts
            </Tab>
            <Tab
              className={
                "p-5 text-center text-3xl font-bold w-1/2 my-5 bg-red-500 hover:bg-red-600 cursor-pointer focus:bg-red-600 "
              }
            >
              Settled Contracts
            </Tab>
          </TabList>

          {/* Panel for Live Contracts */}
          <TabPanel>
            <table className="w-full border-collapse border border-gray-800">
              <thead>
                <tr>
                  <th className="border border-gray-600 px-4 py-2">Contract</th>
                  <th className="border border-gray-600 px-4 py-2">
                    Closing Order Entry
                  </th>
                  <th className="border border-gray-600 px-4 py-2">Position</th>
                  <th className="border border-gray-600 px-4 py-2">
                    Average Price
                  </th>
                  <th className="border border-gray-600 px-4 py-2">
                    Live Price
                  </th>
                  <th className="border border-gray-600 px-4 py-2">
                    Live Points
                  </th>
                </tr>
              </thead>
              <tbody>
                {liveContracts.map((contract) => (
                  <React.Fragment key={contract.id}>
                    <tr
                      onClick={() => toggleContractExpansion(contract.id)}
                      className="cursor-pointer"
                    >
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.name}
                      </td>
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.closingOrderEntry}
                      </td>
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.position}
                      </td>
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.averagePrice}
                      </td>
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.livePrice}
                      </td>
                      <td className="border border-gray-600 px-4 py-2">
                        {contract.livePoints}
                      </td>
                    </tr>
                    {expandedContract === contract.id && (
                      <tr>
                        <td
                          colSpan="6"
                          className="border border-gray-600 px-4 py-2"
                        >
                          {orders.filter(
                            (order) => order.contractId === contract.id
                          ).length > 0 ? (
                            <ul className="list-disc list-inside">
                              {orders
                                .filter(
                                  (order) => order.contractId === contract.id
                                )
                                .map((order) => (
                                  <li key={order.id} className="text-white">
                                    {`Order ID: ${order.id}, Type: ${order.type}, Price: ${order.price}, Size: ${order.size}`}
                                  </li>
                                ))}
                            </ul>
                          ) : (
                            <p className="text-white">
                              No orders found for {contract.name}
                            </p>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="w-full border-collapse border border-gray-800">
              <thead>
                <tr>
                  <th className="border border-gray-600 px-4 py-2">Contract</th>
                  <th className="border border-gray-600 px-4 py-2">
                    Closing Order Entry
                  </th>
                  <th className="border border-gray-600 px-4 py-2">Position</th>
                  <th className="border border-gray-600 px-4 py-2">
                    Average Price
                  </th>
                  <th className="border border-gray-600 px-4 py-2">Outcome</th>
                  <th className="border border-gray-600 px-4 py-2">
                    Personal Result
                  </th>
                </tr>
              </thead>
              <tbody>
                {settledContracts.map((contract) => (
                  <tr key={contract.id}>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.name}
                    </td>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.closingOrderEntry}
                    </td>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.position}
                    </td>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.averagePrice}
                    </td>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.outcome}
                    </td>
                    <td className="border border-gray-600 px-4 py-2">
                      {contract.personalResult}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Positions;
