// EditOrderModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';

const EditOrderModal = ({ orderId, initialPrice, initialSize, onSave, onClose }) => {
  const [price, setPrice] = useState(initialPrice);
  const [size, setSize] = useState(initialSize);

  const handleSave = () => {
    onSave(orderId, price, size);
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Edit Order Modal"
      className="modal-content bg-gray-800 text-white flex justify-center"
    >
      <div className="bg-gray-900 rounded-lg p-6 w-full max-w-sm">
        <h2 className="text-lg font-bold mb-4">Edit Order</h2>
        <form onSubmit={handleSave}>
          <div className="mb-4">
            <label className="block text-sm font-medium">Price</label>
            <input
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="mt-1 block w-full border-gray-700 bg-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Size</label>
            <input
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className="mt-1 block w-full border-gray-700 bg-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-white"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onClose}
              className="ml-3 inline-flex justify-center px-4 py-2 text-sm font-medium bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditOrderModal;
