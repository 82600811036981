import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleRegister = async () => {
    if (!username || !email || !password) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const newUser = { username, email, password };
      await axios.post("/api/users", newUser);
      navigate("/login");
    } catch (error) {
      console.error("Error registering:", error);
      setError("An error occurred while registering");
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Register</h2>
        <div className="mb-4">
          <input
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <input
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <input
            className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button
          type="button"
          onClick={handleTogglePassword}
          className=" mt-2 mr-4 text-sm text-gray-400 focus:outline-none justify-right"
        >
          {showPassword ? "Hide" : "Show"}
        </button>
        <button
          className="w-full py-3 bg-indigo-500 rounded-lg text-white font-bold hover:bg-indigo-600 transition-colors"
          onClick={handleRegister}
        >
          Register
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};

export default Register;
