import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import EditOrderModal from "./EditOrderModal";

const TradeCenter = () => {
  const [userTrades, setUserTrades] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [openOrdersCount, setOpenOrdersCount] = useState(0);
  const [settledPoints, setSettledPoints] = useState(0);
  const [livePoints, setLivePoints] = useState(0);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [group, setGroup] = useState({});
  const { user } = useAuth();
  const [expandedContracts, setExpandedContracts] = useState([]);
  const { groupId } = useParams();
  const [selectedGroup, setSelectedGroup] = useState(null); // New state for selected group
  const [test, setTest] = useState([]);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState({});
  const [userOrders, setUserOrders] = useState([]);
  const [editOrder, setEditOrder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
          console.error("No user found in localStorage");
          navigate("/login");
          return;
        }

        const [
          tradesResponse,
          contractsResponse,
          ordersResponse,
          userResponse,
          groupsResponse,
          testResponse,
        ] = await Promise.all([
          axios.get(`/api/orders?_limit=10&_sort=id&_order=desc`),
          axios.get("/api/contracts"),
          axios.get(`/api/orders?userId=${user.id}`),
          axios.get(`/api/users/${user.id}`),
          axios.get(`/api/groups/${selectedGroup || groupId}`), // Fetch data based on selected group or groupId from URL
          axios.get(`/api/groups`),
        ]);

        const contractsData = contractsResponse.data.map((contract) => {
          const contractOrders = ordersResponse.data.filter(
            (order) => order.contractId === contract.id
          );
          const asks = contractOrders.filter(
            (order) => order.type.toLowerCase() === "ask"
          );
          const bids = contractOrders.filter(
            (order) => order.type.toLowerCase() === "bid"
          );

          // Find the lowest ask price
          const bestAsk =
            asks.length > 0
              ? asks.reduce((minAsk, ask) =>
                  parseFloat(ask.price) < parseFloat(minAsk.price)
                    ? ask
                    : minAsk
                )
              : null;

          // Find the highest bid price
          const bestBid =
            bids.length > 0
              ? bids.reduce((maxBid, bid) =>
                  parseFloat(bid.price) > parseFloat(maxBid.price)
                    ? bid
                    : maxBid
                )
              : null;

          return {
            ...contract,
            bestAsk,
            bestBid,
            orderCount: contractOrders.length,
          };
        });

        const fetchOrdersAndUsers = async () => {
          try {
            const ordersResponse = await axios.get("/api/orders");
            const usersResponse = await axios.get("/api/users");

            const usersMap = usersResponse.data.reduce((acc, user) => {
              acc[user.id] = user.username; // Or any other user attribute you'd like to display
              return acc;
            }, {});

            setOrders(ordersResponse.data);
            setUsers(usersMap);

            const userOrders = ordersResponse.data.filter(
              (order) => order.userId === user.id
            );
            setUserOrders(userOrders);
          } catch (error) {
            console.error("Error fetching orders and users:", error);
          }
        };

        fetchOrdersAndUsers();

        setUserTrades(
          tradesResponse.data.filter((order) => {
            const contract = contractsResponse.data.find(
              (contract) => contract.id === order.contractId
            );
            return contract && contract.groupId === (selectedGroup || groupId);
          })
        );
        setContracts(
          contractsData.filter(
            (contract) => contract.groupId === (selectedGroup || groupId)
          )
        ); // Filter contracts based on selected group or groupId from URL
        setOpenOrdersCount(ordersResponse.data.length);
        setUserData(userResponse.data);
        setGroup(groupsResponse.data);
        setTest(
          testResponse.data
            .flat()
            .filter((group) => group.members.includes(user.id))
        );
        const filteredGroups = groupsResponse.data.members.includes(user.id)
          ? [groupsResponse.data]
          : [];
        setUserGroups(filteredGroups);
      } catch (error) {
        console.error("Error fetching user groups:", error);
      }
    };
    fetchData();
  }, [user.id, navigate, groupId, selectedGroup]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/login");
  };

  const handleGroupChange = (groupId) => {
    setSelectedGroup(groupId); // Set the selected group
  };

  const calculateTimeLeft = (maturityDateTime) => {
    const now = new Date();
    const maturityDate = new Date(maturityDateTime);
    const timeLeft = maturityDate - now;

    if (timeLeft <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((timeLeft / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.delete(`/api/orders/${orderId}`);
      setOrders(orders.filter((order) => order.id !== orderId));
      setUserOrders(userOrders.filter((order) => order.id !== orderId));

      // Update the orders within the expanded contract details
      setContracts((prevContracts) =>
        prevContracts.map((contract) =>
          contract.orders
            ? {
                ...contract,
                orders: contract.orders.filter((order) => order.id !== orderId),
              }
            : contract
        )
      );
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleEditOrder = (orderId) => {
    const orderToEdit = orders.find((order) => order.id === orderId);
    setEditOrder(orderToEdit);
  };

  const handleSaveEditOrder = async (orderId, newPrice, newSize) => {
    try {
      const updatedOrder = { price: newPrice, size: newSize };
      await axios.patch(`/api/orders/${orderId}`, updatedOrder);
      const updatedOrders = orders.map((order) =>
        order.id === orderId
          ? { ...order, price: newPrice, size: newSize }
          : order
      );
      const updatedUserOrders = userOrders.map((order) =>
        order.id === orderId
          ? { ...order, price: newPrice, size: newSize }
          : order
      );
      setOrders(updatedOrders);
      setUserOrders(updatedUserOrders);
    } catch (error) {
      console.error("Error editing order:", error);
    }
  };

  const toggleContractDetails = async (contractId) => {
    if (expandedContracts.includes(contractId)) {
      setExpandedContracts(expandedContracts.filter((id) => id !== contractId));
    } else {
      const contractResponse = await axios.get(`/api/contracts/${contractId}`);
      const ordersResponse = await axios.get(
        `/api/orders?contractId=${contractId}`
      );
      setContracts((prevContracts) =>
        prevContracts.map((contract) =>
          contract.id === contractId
            ? {
                ...contract,
                details: contractResponse.data,
                orders: ordersResponse.data,
              }
            : contract
        )
      );
      setExpandedContracts([...expandedContracts, contractId]);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-10 px-6">
      <div className="container mx-auto">
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-4xl mx-auto flex justify-between items-center mb-6">
          <div>
            {editOrder && (
              <EditOrderModal
                orderId={editOrder.id}
                initialPrice={editOrder.price}
                initialSize={editOrder.size}
                onSave={handleSaveEditOrder}
                onClose={() => setEditOrder(null)}
              />
            )}
            <select
              value={selectedGroup || groupId}
              className="bg-gray-800 text-4xl font-bold mb-2 border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:focus:border-blue-500"
              onChange={(e) => handleGroupChange(e.target.value)}
            >
              {test.map((group) => (
                <option
                  className="text-xl font-bold"
                  key={group.id}
                  value={group.id}
                  selected={group.id === (selectedGroup || groupId)}
                >
                  {group.name}
                </option>
              ))}
            </select>
            <p className="text-xl">{userData.username}</p>
          </div>{" "}
          <Link className="text-2xl font-bold text-indigo-500" to="/contracts">
            {" "}
            Contracts{" "}
          </Link>
          <div>
            <button
              className="text-indigo-500 hover:text-indigo-400"
              onClick={handleLogout}
            >
              Logout
            </button>
            <Link
              className="ml-4 text-indigo-500 hover:text-indigo-400"
              to="/my-account"
            >
              My Account
            </Link>
          </div>
          <div className="text-right">
            <p>
              Settled points: <span className="font-bold">{settledPoints}</span>
            </p>
            <p>
              Live Points: <span className="font-bold">{livePoints}</span>
            </p>
            <p>
              Open orders: <span className="font-bold">{openOrdersCount}</span>
            </p>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6 overflow-x-auto">
          <h2 className="text-2xl font-bold mb-4">Last 10 Trades</h2>
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="w-1/4 py-3 px-4 text-left">Contract</th>
                <th className="w-1/4 py-3 px-4 text-left">Execution Time</th>
                <th className="w-1/4 py-3 px-4 text-left">Price</th>
                <th className="w-1/4 py-3 px-4 text-left">Contract ID</th>
                <th className="w-1/4 py-3 px-4 text-left">Comments</th>
              </tr>
            </thead>
            <tbody>
              {userTrades.map((trade) => (
                <tr key={trade.id} className="bg-gray-700">
                  <td className="py-3 px-4">
                    {
                      contracts.find(
                        (contract) => contract.id === trade.contractId
                      )?.name
                    }
                  </td>
                  <td className="py-3 px-4">{trade.executionTime}</td>
                  <td className="py-3 px-4">{trade.price}</td>
                  <td className="py-3 px-4">{trade.contractId}</td>
                  <td className="py-3 px-4">
                    {contracts.find((contract) => contract.id).description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg overflow-x-auto">
          <h2 className="text-2xl font-bold mb-4">All Contracts</h2>
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="w-1/2 py-3 px-4 text-left">Contract</th>
                <th className="w-1/2 py-3 px-4 text-left">Traded Contracts</th>
                <th className="w-1/2 py-3 px-4 text-left">Remaining Time</th>
                <th className="w-1/4 py-3 px-4 text-left">Best Ask Price</th>
                <th className="w-1/4 py-3 px-4 text-left">Best Ask Size</th>
                <th className="w-1/4 py-3 px-4 text-left">Best Bid Price</th>
                <th className="w-1/4 py-3 px-4 text-left">Best Bid Size</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((contract) => (
                <React.Fragment key={contract.id}>
                  <tr className="bg-gray-700">
                    <td className="py-3 px-4">
                      <button
                        className="text-indigo-400 hover:underline font-bold"
                        onClick={() => toggleContractDetails(contract.id)}
                      >
                        {contract.name}
                      </button>
                    </td>
                    <td className="py-3 px-4">{contract.orderCount}</td>
                    <td className="py-3 px-4">
                      {calculateTimeLeft(contract.maturityDateTime)}
                    </td>
                    <td className="py-3 px-4">
                      {contract.bestAsk ? `${contract.bestAsk.price}` : "N/A"}
                    </td>
                    <td className="py-3 px-4">
                      {contract.bestAsk ? `${contract.bestAsk.size}` : "N/A"}
                    </td>
                    <td className="py-3 px-4">
                      {contract.bestBid ? `${contract.bestBid.price}` : "N/A"}
                    </td>
                    <td className="py-3 px-4">
                      {contract.bestBid ? `${contract.bestBid.size}` : "N/A"}
                    </td>
                  </tr>
                  {expandedContracts.includes(contract.id) && (
                    <>
                      <Link
                        className="ml-4 text-indigo-500 hover:text-indigo-400"
                        to={`/order/${contract.id}`}
                      >
                        Order
                      </Link>
                      <Link
                        className="ml-4 text-indigo-500 hover:text-indigo-400"
                        to={`/positions/${group.id}`}
                      >
                        Positions
                      </Link>
                      <tr className="bg-gray-700">
                        <td colSpan="7" className="py-3 px-4">
                          <div className="bg-gray-800 p-4 rounded-lg">
                            <h3 className="text-xl font-bold mb-2">
                              Contract Details
                            </h3>
                            <p>
                              {contract.details?.description ||
                                "No description available"}
                            </p>
                            <h4 className="text-2xl font-bold mt-4 mb-2">
                              Orders
                            </h4>
                            <table className="w-full">
                              <thead>
                                <tr>
                                  <th className="text-left text-xl" colSpan="3">
                                    Ask Orders
                                  </th>
                                  <th colSpan="1"></th>
                                  <th className="text-left text-xl" colSpan="3">
                                    Bid Orders
                                  </th>
                                </tr>
                                <tr>
                                  <th className="text-left">Price</th>
                                  <th className="text-left">Size</th>
                                  <th className="text-left">Action</th>
                                  <th></th>
                                  <th className="text-left">Price</th>
                                  <th className="text-left">Size</th>
                                  <th className="text-left">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {contract.orders &&
                                contract.orders.length > 0 ? (
                                  <>
                                    {contract.orders
                                      .filter(
                                        (order) =>
                                          order.type.toLowerCase() === "ask" ||
                                          order.type.toLowerCase() === "bid"
                                      )
                                      .sort((a, b) => {
                                        if (
                                          a.type.toLowerCase() === "bid" &&
                                          b.type.toLowerCase() === "bid"
                                        ) {
                                          return (
                                            parseFloat(b.price) -
                                            parseFloat(a.price)
                                          ); // Sort bid orders in descending order
                                        } else if (
                                          a.type.toLowerCase() === "ask" &&
                                          b.type.toLowerCase() === "ask"
                                        ) {
                                          return (
                                            parseFloat(a.price) -
                                            parseFloat(b.price)
                                          ); // Sort ask orders in ascending order
                                        } else {
                                          return 0;
                                        }
                                      })
                                      .map((order) => (
                                        <tr key={order.id}>
                                          {order.type.toLowerCase() ===
                                          "ask" ? (
                                            <>
                                              <td>{order.price}</td>
                                              <td>{order.size}</td>
                                              <td>
                                                <button
                                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                                  onClick={() =>
                                                    handleEditOrder(order.id)
                                                  }
                                                >
                                                  Edit
                                                </button>
                                                <button
                                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                  onClick={() =>
                                                    handleDeleteOrder(order.id)
                                                  }
                                                >
                                                  Delete
                                                </button>
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </>
                                          ) : (
                                            <>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>{order.price}</td>
                                              <td>{order.size}</td>
                                              <td>
                                                <button
                                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                                  onClick={() =>
                                                    handleEditOrder(order.id)
                                                  }
                                                >
                                                  Edit
                                                </button>
                                                <button
                                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                  onClick={() =>
                                                    handleDeleteOrder(order.id)
                                                  }
                                                >
                                                  Delete
                                                </button>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan="7" className="text-left py-4 ">
                                      No orders available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TradeCenter;
