import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import EditOrderModal from "./EditOrderModal";

const GlobalOrderBook = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useAuth();
  const [users, setUsers] = useState({});
  const [userOrders, setUserOrders] = useState([]);
  const [editOrder, setEditOrder] = useState(null);

  useEffect(() => {
    const fetchOrdersAndUsers = async () => {
      try {
        const ordersResponse = await axios.get("/api/orders");
        const usersResponse = await axios.get("/api/users");

        const usersMap = usersResponse.data.reduce((acc, user) => {
          acc[user.id] = user.username; // Or any other user attribute you'd like to display
          return acc;
        }, {});

        setOrders(ordersResponse.data);
        setUsers(usersMap);

        const userOrders = ordersResponse.data.filter(
          (order) => order.userId === user.id
        );
        setUserOrders(userOrders);
      } catch (error) {
        console.error("Error fetching orders and users:", error);
      }
    };

    fetchOrdersAndUsers();
  }, [user.id]);

  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.delete(`/api/orders/${orderId}`);
      setOrders(orders.filter((order) => order.id !== orderId));
      setUserOrders(userOrders.filter((order) => order.id !== orderId));
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleEditOrder = (orderId) => {
    const orderToEdit = orders.find((order) => order.id === orderId);
    setEditOrder(orderToEdit);
  };

  const handleSaveEditOrder = async (orderId, newPrice, newSize) => {
    try {
      const updatedOrder = { price: newPrice, size: newSize };
      await axios.patch(`/api/orders/${orderId}`, updatedOrder);
      const updatedOrders = orders.map((order) =>
        order.id === orderId
          ? { ...order, price: newPrice, size: newSize }
          : order
      );
      const updatedUserOrders = userOrders.map((order) =>
        order.id === orderId
          ? { ...order, price: newPrice, size: newSize }
          : order
      );
      setOrders(updatedOrders);
      setUserOrders(updatedUserOrders);
    } catch (error) {
      console.error("Error editing order:", error);
    }
  };

  const bidOrders = orders.filter((order) => order.type === "bid");
  const askOrders = orders.filter((order) => order.type === "ask");

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 m-5 p-8 rounded-lg shadow-lg w-full max-w-xl">
        <h2 className="text-2xl font-bold mb-6">Global Order Book</h2>
        {editOrder && (
          <EditOrderModal
            orderId={editOrder.id}
            initialPrice={editOrder.price}
            initialSize={editOrder.size}
            onSave={handleSaveEditOrder}
            onClose={() => setEditOrder(null)}
          />
        )}
        <div className="grid grid-cols-2 gap-3 mb-4">
          <div className="col-span-1">
            <h3 className="text-lg font-bold mb-2">Ask Orders</h3>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Price</th>
                  <th className="text-left">Size</th>
                  <th className="text-left">User</th>
                </tr>
              </thead>
              <tbody>
                {askOrders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.price}</td>
                    <td>{order.size}</td>
                    <td>{users[order.userId] || "Unknown user"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-span-1 ">
            <div className="border-l border-gray-700 pl-4">
              <h3 className="text-lg font-bold mb-2">Bid Orders</h3>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left">Price</th>
                    <th className="text-left">Size</th>
                    <th className="text-left">User</th>
                  </tr>
                </thead>
                <tbody>
                  {bidOrders.map((order) => (
                    <tr key={order.id}>
                      <td>{order.price}</td>
                      <td>{order.size}</td>
                      <td>{users[order.userId] || "Unknown user"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-span-1 ">
          <h3 className="text-lg font-bold mb-2">Your Orders</h3>
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left">Type</th>
                <th className="text-left">Price</th>
                <th className="text-left">Size</th>
                <th className="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {userOrders.map((order) => (
                <tr key={order.id}>
                  <td>{order.type}</td>
                  <td>{order.price}</td>
                  <td>{order.size}</td>
                  <td>
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                      onClick={() => handleEditOrder(order.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleDeleteOrder(order.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default GlobalOrderBook;
