import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Contracts = () => {
  const [newContract, setNewContract] = useState("");
  const [description, setDescription] = useState("");
  const [maturityDateTime, setMaturityDateTime] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const fetchGroups = async () => {
        try {
          const groupResponse = await axios.get(
            `/api/groups?createdBy=${user.id}`
          );
          setGroups(groupResponse.data);
          if (groupResponse.data.length > 0) {
            setSelectedGroupId(groupResponse.data[0].id);
          }
        } catch (error) {
          console.error("Error fetching groups:", error);
        }
      };
      fetchGroups();
    }
  }, []);

  const handleCreateContract = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const contract = {
        name: newContract,
        createdBy: user.email,
        groupId: selectedGroupId,
        description: description,
        maturityDateTime: new Date(maturityDateTime).toISOString(),
      };
      await axios.post("/api/contracts", contract);
      setNewContract("");
      setMaturityDateTime("");
      setDescription("");
    } catch (error) {
      console.error("Error creating contract:", error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 m-5 p-8 rounded-lg shadow-lg w-full max-w-xl">
        <h2 className="text-2xl font-bold mb-6">Create Contract</h2>
        {groups.length > 0 ? (
          <>
            <select
              className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500 mb-4"
              value={selectedGroupId}
              onChange={(e) => setSelectedGroupId(e.target.value)}
            >
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
            <input
              className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
              type="text"
              placeholder="New Contract"
              value={newContract}
              required
              onChange={(e) => setNewContract(e.target.value)}
            />
            <input
              className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
              type="datetime-local"
              value={maturityDateTime}
              onChange={(e) => setMaturityDateTime(e.target.value)}
              required
            />
            <textarea
              className="w-full p-3 border border-gray-700 rounded-lg bg-gray-900 focus:outline-none focus:border-indigo-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              required
              placeholder="Add some description here"
            ></textarea>
            <button
              className="w-full py-3 my-3 bg-indigo-500 rounded-lg text-white font-bold hover:bg-indigo-600 transition-colors"
              onClick={handleCreateContract}
            >
              Create Contract
            </button>
          </>
        ) : (
          <div>
            <p>You need to create a group first before creating contracts.</p>
            <Link
              className="w-full py-3 my-3 bg-indigo-500 rounded-lg text-white font-bold hover:bg-indigo-600 transition-colors"
              to="/create-group"
            >
              Create Group
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contracts;
